'use client';

import React, { ComponentType, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { MIXPANEL_EVENT_NAMES, MixpanelEventName } from '@shared/api/mixPanel';
import NavLayoutLink from '@shared/common/components/NavLayout/NavLayoutLink';
import { NavLayoutLinkBase } from '@shared/common/components/NavLayout/types';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useMixPanel, useTrackMixPanel } from '@shared/common/providers/MixPanelProvider';
import { GENERAL_URLS, nextProjectUrl } from '@shared/constants/url';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { Blocks, Fire, Verificated } from '@ui-uikit/lib/components/icons/mono';
import {
  Boost,
  Dao,
  Launchpad,
  MagicIDLogo,
  MagicsquareLogo,
  Otc,
  Staking,
  Swap,
} from '@ui-uikit/lib/components/logos/';

type PrimaryNav = NavLayoutLinkBase & {
  Icon: ComponentType<{ className?: string }>;
  mixPanelEventName?: MixpanelEventName;
  isHidden?: boolean;
};

const secondaryNavBase: {
  id: string;
  link: Omit<NavLayoutLinkBase, 'id'>;
  Icon: ComponentType<{ className?: string }>;
  name: ReactNode;
}[] = [
  {
    id: 'store-hot-offers-page',
    link: {
      href: nextProjectUrl.store('/hot-offers'),
      label: 'View Hot Offers',
    },
    name: (
      <>
        Rewards, <br /> Hot Offers & More
      </>
    ),
    Icon: ({ className }) => <Fire className={classNames('text-base-text-red', className)} />,
  },
  {
    id: 'store-main-page',
    link: {
      href: nextProjectUrl.store('/'),
      label: 'Go to Magic Store',
    },
    name: (
      <>
        Handpicked <br /> Web3 Content
      </>
    ),
    Icon: ({ className }) => <Blocks className={classNames('text-base-text-blue', className)} />,
  },
  {
    id: 'submit-app',
    link: {
      href: nextProjectUrl.store('/profile/become-creator'),
      label: 'Submit Application',
      isAuthRequired: true,
    },
    name: (
      <>
        List your Project <br /> on the Magic Store
      </>
    ),
    Icon: ({ className }) => (
      <Verificated className={classNames('text-base-text-pink', className)} />
    ),
  },
];

const HomePage = () => {
  const { user } = useAuth();
  const { track } = useMixPanel();

  useTrackMixPanel(MIXPANEL_EVENT_NAMES.ROOT_DOMAIN_PAGE_VIEW, {
    distinct_id: user?.id as number,
  });

  const trackTilesLinkClick = (eventName: MixpanelEventName) => {
    if (user?.id) {
      track(eventName, {
        distinct_id: user.id,
      });
    }
  };

  const { enableMagicBoostApp } = useFeatureToggles();

  const primaryNav = useMemo((): PrimaryNav[] => {
    const products: PrimaryNav[] = [
      {
        id: 'magic-id',
        href: nextProjectUrl.store('/profile/settings'),
        label: 'Magic ID',
        Icon: MagicIDLogo,
        isAuthRequired: true,
      },
      {
        id: 'store',
        href: nextProjectUrl.store('/'),
        label: 'Magic Store',
        Icon: MagicsquareLogo,
      },
      {
        id: 'launchpad',
        href: nextProjectUrl.launchpad('/'),
        label: 'Launchpad',
        Icon: Launchpad,
        mixPanelEventName: MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK,
      },
      {
        id: 'staking',
        href: nextProjectUrl.staking('/'),
        label: 'Staking',
        Icon: Staking,
        mixPanelEventName: MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK,
      },
      {
        id: 'swap',
        href: nextProjectUrl.swap('/'),
        label: 'Swap',
        Icon: Swap,
      },
      {
        id: 'boost',
        href: nextProjectUrl.store('/magic-boost'),
        label: 'Boost',
        Icon: Boost,
        isAuthRequired: true,
        isHidden: !enableMagicBoostApp,
      },
      {
        id: 'otc',
        href: GENERAL_URLS.otc,
        label: 'OTC',
        Icon: Otc,
        external: true,
        mixPanelEventName: MIXPANEL_EVENT_NAMES.OTC_LINK_CLICK,
      },
      {
        id: 'dao',
        href: GENERAL_URLS.dao,
        label: 'DAO',
        Icon: Dao,
        external: true,
      },
    ];

    return products.filter(({ isHidden }) => !isHidden);
  }, [enableMagicBoostApp]);

  const secondaryNav = useMemo(() => {
    // change submit-app href for developer
    if (user?.attributes?.creatorId) {
      return secondaryNavBase.map((item) => ({
        ...item,
        link:
          item.id === 'submit-app'
            ? { ...item.link, href: nextProjectUrl.store('/profile/upload-app') }
            : item.link,
      }));
    }

    return secondaryNavBase;
  }, [user?.attributes?.creatorId]);

  return (
    <main className="v2-container pt-12 xl-msq:pt-32">
      <h1 className="text-h1-a mb-12 xl-msq:mb-16 text-center">
        Magic&nbsp;Square: Discover & Engage
        <br /> with the&nbsp;Ultimate Web3 App Store
      </h1>

      <nav aria-label="Products" className="3xl-msq:max-w-[57rem] mx-auto mb-12 xl-msq:mb-32">
        <ul className="grid grid-cols-2 gap-2 xl-msq:gap-4 md-msq:grid-cols-4">
          {primaryNav.map(
            ({ id, href, label, isAuthRequired, external, Icon, mixPanelEventName }) => {
              return (
                <li key={id}>
                  <NavLayoutLink
                    onClick={() => mixPanelEventName && trackTilesLinkClick(mixPanelEventName)}
                    isAuthRequired={isAuthRequired}
                    href={href}
                    external={external}
                    className={classNames(
                      'bg-base-bg-tertiary rounded-2xl pt-[2.125rem] pb-[1.625rem] md-msq:pt-[2.4375rem] md-msq:pb-[1.9375rem] xl-msq:pt-[2.875rem] xl-msq:pb-[2.375rem] 3xl-msq:pt-[2.625rem] 3xl-msq:pb-[2.125rem] gap-4 xl-msq:gap-5 3xl-msq:gap-4 flex flex-col items-center md-msq:rounded-3xl',
                      'transition-shadow hover:shadow-inset-border hover:shadow-base-border-medium',
                    )}
                  >
                    <Icon className="size-[4rem] md-msq:size-[4.5rem] xl-msq:size-[6.5rem] 3xl-msq:size-[6rem]" />

                    <span className="text-h4-a text-center">{label}</span>
                  </NavLayoutLink>
                </li>
              );
            },
          )}
        </ul>
      </nav>
      <h2 className="text-h2-a mb-10 text-center xl-msq:mb-[3.5rem]">
        Magic&nbsp;Square: Discover Web3 Apps&nbsp;&&nbsp;Games
      </h2>
      <ul className="flex flex-col gap-2 md-msq:flex-row xl-msq:gap-4">
        {secondaryNav.map(({ id, link, name, Icon }) => (
          <li
            key={id}
            className="flex w-full flex-col gap-6 xl-msq:gap-8 justify-center items-center p-8 pt-10 xl-msq:pt-14 xl-msq:pb-12 rounded-3xl shadow-inset-border shadow-base-border-medium"
          >
            <Icon className="size-12 xl-msq:size-14" />
            <p className="text-h3-a text-center">{name}</p>
            <NavLayoutLink
              external={link.external}
              href={link.href}
              isAuthRequired={link.isAuthRequired}
              className="msq-btn msq-btn-lg msq-btn-primary w-full xl-msq:max-w-[13.5rem]"
            >
              {link.label}
            </NavLayoutLink>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default HomePage;
